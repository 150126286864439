.imigoAnimationBg{
    background: linear-gradient(270deg, #fe7d45, #fa4d98, #24c5c9, #216c7f);
    background-size: 200% 200% !important;
    animation: background 37s ease infinite !important;

}


@keyframes background {
    0%{background-position:0% 50%  }
    50%{background-position:100% 50% }
    100%{background-position:0% 50% }
}

.resetPass{
    padding: 50px;
    color: white;
}
.resetPass form{
    width: 100%;
}
.resetPass input,.resetPass textarea {
    display: block;
    margin: 10px 0;
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    color: black;
}

.resetPass button{
    background: WHITE;
    color: black;
    padding: 10px 20px;
    border-radius: 10px;
    width: 100%;
    text-align: center;
}